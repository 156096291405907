import "../style/home.css";
import twitter from "../images/twitter.svg";
import facebook from "../images/Facebook.svg";
import instagram from "../images/instagram.svg";
import linkedin from "../images/linkedin.svg";
import accents from "../images/accents.svg";
import galaxy2 from "../images/galaxy2.jpg";
import man from "../images/man.svg";
import plane from "../images/plane.svg";
import clinetRev from "../images/clinetRev.svg";
import review from "../images/review.svg";
import review2 from "../images/review2.svg";
import accent2 from "../images/accent2.svg";
import thirdImg from "../images/thirdImg.svg";
import triangle from "../images/triangle.svg";
import shield from "../images/shield.gif";
import shadow1 from "../images/shadow1.png";
import shadow2 from "../images/shadow2.png";
import triangles from "../images/triangles.svg";
import bdaiatWhiteLogo from "../images/bdaiatWLogo.png";
import { useNavigate } from "react-router-dom";

import cardOneImage from "../images/ceoImage.png";
import cardOneLogo from "../images/ceoLogo.png";

import cardTwoImage from "../images/ctoImage.png";
import cardTwoLogo from "../images/ctoLogo.png";

import cardThreeImage from "../images/tlImage.png";
import cardThreeLogo from "../images/tlLogo.png";

import cardFourImage from "../images/baImage.png";
import cardFourLogo from "../images/baLogo.png";

// import cardFiveImage from"../images/devImage.png"
import cardFiveLogo from "../images/devLogo.png";

import uiuxD from "../images/uiuxD.svg";
import qcD from "../images/qcD.svg";
import mobileD from "../images/mobileD.svg";
import graphicD from "../images/graphicD.svg";
import webD from "../images/webD.svg";

import step1 from "../images/step1.jpeg";
import step2 from "../images/step2.jpeg";
import step3 from "../images/step3.jpeg";
import step4 from "../images/step4.jpeg";

import banners from "../images/banners.gif";
import Component from "../images/Component.svg";
import client1 from "../images/client1.svg";
import Footer from "../components/Footer";
import client2 from "../images/client2.svg";
import client3 from "../images/client3.svg";
import client4 from "../images/client4.svg";
import client5 from "../images/client5.svg";
import client6 from "../images/client6.svg";
import proj1 from "../images/proj1.svg";
import proj2 from "../images/proj2.svg";
import proj3 from "../images/proj3.svg";
import { PuffLoader } from "react-spinners";
import MessengerCustomerChat from "react-messenger-customer-chat";
import { Link } from "react-router-dom";
import uiux from "../images/uiux.svg";
import graphic from "../images/graphic.svg";
import mobile from "../images/mobile.svg";
import web from "../images/web.svg";
import buildTriangle from "../images/buildTriangle.svg";
import { VscTriangleRight } from "react-icons/vsc";
import { BsArrowRightShort } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import Modal from "../components/Modal";
import Content from "../images/Content.png";
import { getClients } from "../api";
import quotes from "../images/quotes.svg";
import quotes2 from "../images/quotes2.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import Navbar from "../components/Navbar";
import { TbArrowBarToUp } from "react-icons/tb";
import { BsCircleFill } from "react-icons/bs";
import Feedback from "../components/Feedback";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import { useInView } from "react-intersection-observer";
import { RiDoubleQuotesL } from "react-icons/ri";
import { useEffect } from "react";
// import required modules
import { Navigation } from "swiper";
import smile from "../images/smile.svg";
import { getFeedback, getProjects, getBlogs } from "../api";
import { RxDoubleArrowRight } from "react-icons/rx";
import { useState, useRef } from "react";
import Success from "../components/Success";
import { Helmet } from "react-helmet-async";

function Home() {
  const [more, setMore] = useState(false);
  const [up, setUp] = useState(false);
  const [modal, setModal] = useState(false);
  const [clinets, setClients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [feedback, setFeedback] = useState([]);
  const [projects, setProjects] = useState([]);
  const [blogs, setBlogs] = useState([]);
  const [success, setSuccess] = useState(false);

  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);

  const { t, i18n } = useTranslation();

  const currentLanguage = i18n.language;
  const { ref: teamRef, inView: myElementIsVisible } = useInView();
  const { ref: topRef, inView: topVisible } = useInView();

  const [scrollPosition, setScrollPosition] = useState(0);

  const history = useNavigate();

  // const handleClick = () => {
  //   const section = document.getElementById("processSection");
  //   section.scrollIntoView({ behavior: "smooth" });
  // };

  // var targetDiv = document.getElementById('targetDiv');
  // var hasScrolledToDiv = false; // Flag to control the scroll behavior
  // var lastScroll = window.pageYOffset; // Keep track of the last scroll position

  // // Function to handle scroll events
  // function handleScroll() {
  //   var currentScroll = window.pageYOffset;
  //   var divPosition = targetDiv.getBoundingClientRect();
  //   var viewportHeight = window.innerHeight || document.documentElement.clientHeight;

  //   // Check if scrolling down and the top of the div is visible and hasn't scrolled to it yet
  //   if (currentScroll > lastScroll && divPosition.top >= 0 && divPosition.top < viewportHeight && !hasScrolledToDiv) {
  //     targetDiv.scrollIntoView({ behavior: 'auto', block: 'start' }); // Modify this as needed
  //     hasScrolledToDiv = true; // Update flag
  //   } else if (currentScroll < lastScroll) {
  //     // Optionally, reset the flag if scrolling up to allow re-triggering under certain conditions
  //     hasScrolledToDiv = false;
  //   }

  //   lastScroll = currentScroll; // Update last scroll position
  // }

  // // Throttle scroll events using requestAnimationFrame
  // var scrollTimeout;
  // window.addEventListener('scroll', function() {
  //   if (!scrollTimeout) {
  //     scrollTimeout = requestAnimationFrame(function() {
  //       handleScroll();
  //       scrollTimeout = null;
  //     });
  //   }
  // });

  const handleGoToQuotationByHeader = () => {
    localStorage.setItem("source", "headerBtn");
    history("/quotation");
  };
  const handleGoToQuotationByBuildYourTeam = () => {
    localStorage.setItem("source", "buildYourTeamBtn");
    history("/quotation");
  };
  const handleGoToQuotationByHomeKickOff = () => {
    localStorage.setItem("source", "HomekickOffBtn");
    history("/quotation");
  };

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const translateY = scrollPosition * 0.3; // Adjust the multiplier as needed -> from 0.5

  // const [scrollY, setScrollY] = useState(0);

  // const handleScroll = () => {
  //     setScrollY(window.scrollY);
  // };

  // useEffect(() => {
  //     window.addEventListener('scroll', handleScroll);

  //     return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //     };
  // }, []);

  // const marginTop = Math.max(0, 200 - scrollY); // Adjust the margin dynamically

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("language")) === "ar") {
      document.title = " بدايات للتكنولوجيا";
    } else {
      document.title = "BDAIAT TECHNOLOGIES";
    }

    getClients().then((response) => {
      setClients(response.data.data);
    });

    getFeedback().then((response) => {
      setFeedback(response.data.data);
    });
    getProjects().then((response) => {
      setProjects(response.data.data);
      setLoading(false);
    });
    getBlogs().then((response) => {
      setBlogs(response.data.data);
      setLoading(false);
    });
  }, []);

  window.onscroll = function (e) {
    if (myElementIsVisible) {
      setUp(true);
    } else if (!myElementIsVisible && topVisible) {
      setUp(false);
    }
  };

  function WordCount(str) {
    return str.split(" ").length;
  }

  return (
    <div className="changeBckg home-container" style={{ overflow: "hidden" }}>
      <Helmet>
        <title>Bdaiat Technologies</title>
        <link rel="canonical" href="https://www.bdaiat.com/" />

        <script type="application/ld+json">{`
          {"@context":"http://schema.org","@type":"Organization","name":"Bdaiat Technolgoies","@id":"BdaiatTechnologies","url":"https://www.bdaiat.com/","logo":"https://www.bdaiat.com/bdaiat-logo.png","address":{"@type":"PostalAddress","addressLocality":"Sidi Gaber","addressRegion":"Alexandria","postalCode":"21523","streetAddress":"Building #378 Horya Road, Sidi Gaber, Alexandria, Egypt","addressCountry":"Egypt"},"email":"mailto:info@bdaiat.com","contactPoint":[{"@type":"ContactPoint","telephone":"+201097101006","contactType":"Technical Support"}],"sameAs":["https://www.facebook.com/bdaiat","https://www.instagram.com/bdaiat/","https://twitter.com/BdaiatTech","https://www.linkedin.com/company/bdaiat"]}
      `}</script>
      </Helmet>
      {success ? <Success type="job" setModal={setSuccess} /> : null}
      {modal ? (
        <Modal setSuccess={setSuccess} type="freelance" setModal={setModal} />
      ) : null}

      {loading ? (
        <div className="loaderDiv" style={{ height: "50vh" }}>
          <PuffLoader size={80} color="#3B6DEA" />
        </div>
      ) : (
        <>
          <button
            id="toTop"
            className={up ? "showUp" : "hideUp"}
            onClick={() => {
              window.scrollTo(0, 0);
            }}
          >
            <TbArrowBarToUp size={40} />
          </button>

          <div
            className={`drkImg ${currentLanguage === "ar" ? "ar-background" : "en-background"}`}
            style={{ transform: `translate3d(0, ${translateY}px, 0)` }}>
            <div className="drkImgContent">
              <MessengerCustomerChat
                pageId="317388765490895"
                theme_color="#3B6DEA"
                appId="6786932621387701"
              />
              <div className="firstDiv marginTopFirst container-fluid">
                <div className="row ">
                  <div className="col-lg-7  alignVert">
                    <div>
                      <svg
                        className="blackBck"
                        xmlns="http://www.w3.org/2000/svg"
                        width="1124"
                        height="928"
                        viewBox="0 0 1124 928"
                        fill="none"
                      >
                        <path
                          d="M281.182 0H0V928H28.4096H79.4012H160.259H332.174L662.891 923.598H724.809H794.558H904.372H1124L1016.19 801.821L891.624 670.508L669.447 424.753L281.182 0Z"
                          fill="#010F16"
                        />
                      </svg>

                      <div style={{ zIndex: "1", position: "relative" }}>
                        <p id="turnIdeas">
                          {t("ideas.title")} <br /> {t("ideas.title2")}{" "}
                          <span>{t("ideas.title3")} </span> {t("ideas.title4")}{" "}
                        </p>
                        <p id="powerful">{t("ideas.text")} </p>
                        <div className="btnsDiv">
                          {/* <Link to="/quotation">
                              <button className="request">
                                {t("navbar.quotation")}
                              </button>{" "}
                            </Link> */}
                          <button
                            onClick={() => handleGoToQuotationByHeader()}
                            className="request"
                          >
                            {t("navbar.quotation")}
                          </button>{" "}
                          <Link to="/about-us">
                            <button className="learnMore">
                              {t("ideas.learn")}{" "}
                              <RxDoubleArrowRight size={20} />
                            </button>
                          </Link>
                        </div>
                        <div className=" socialBtns">
                          <div className="fb">
                            <a
                              href="https://www.facebook.com/bdaiat/"
                              target="_blank"
                            >
                              {" "}
                              <img src={facebook} />
                              {t("ideas.fb")}{" "}
                            </a>
                          </div>
                          <div>
                            <a
                              href="https://twitter.com/BdaiatTech"
                              target="_blank"
                            >
                              {" "}
                              <img src={twitter} />
                              {t("ideas.twt")}{" "}
                            </a>
                          </div>
                          <div>
                            <a
                              href="https://www.linkedin.com/company/bdaiat"
                              target="_blank"
                            >
                              {" "}
                              <img src={linkedin} />
                              {t("ideas.li")}{" "}
                            </a>
                          </div>
                          <div>
                            <a
                              href="https://www.instagram.com/bdaiat/"
                              target="_blank"
                            >
                              {" "}
                              <img src={instagram} />
                              {t("ideas.ig")}{" "}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5 firstDivImg">
                    <img className="review" src={review} />
                    <img className="review2" src={review2} />
                    <div
                      className="accent"
                      style={{ backgroundImage: `url(${accents})` }}
                    ></div>
                    {/* <div className="manImg"  style={{backgroundImage:`url(${man})`}}>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>


          {/* start services */}
          <div className="fourthDiv container-fluid" id="services">
            <div className="row">
              <div
                className="col-lg-12 vertAlign"
                style={{ textAlign: "left" }}
              >
                <div>
                  <p id="ourTeam">
                    {t("services.services")}{" "}
                    <span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </span>
                  </p>
                  <p id="doesMore">{t("services.doesMore")}</p>
                  <p id="doesMore2">{t("services.doesMore2")}</p>
                  {/* <Link to="/services">
                    <button className="more">{t("services.view")}</button>
                  </Link> */}
                </div>
              </div>
              <div className="container-fluid servicesDiv">
                <div className="row gy-4 strech">
                  <div className="col-lg-3 col-md-4 col-sm-6">
                    <div className="square service">
                      <div>
                        <p
                          id="squareTitle"
                          className={`${
                            currentLanguage === "ar"
                              ? "arabicStyleSquareTitle"
                              : ""
                          }`}
                        >
                          {t("services.squareTitle")}
                        </p>
                        <div className="dark-img">
                          <img src={webD} className="img-dark" />
                          <img src={web} className="img-light" />{" "}
                        </div>
                        <p id="squareData">{t("services.squareData")}</p>
                      </div>{" "}
                    </div>{" "}
                  </div>

                  <div className="col-lg-3 col-md-4 col-sm-6">
                    <div className="square service">
                      <div>
                        <p
                          id="squareTitle"
                          className={`${
                            currentLanguage === "ar"
                              ? "arabicStyleSquareTitle"
                              : ""
                          }`}
                        >
                          {t("services.squareTitle1")}
                        </p>
                        <div className="dark-img">
                          <img src={mobileD} className="img-dark" />

                          <img src={mobile} className="img-light" />
                        </div>{" "}
                        <p id="squareData">{t("services.squareData1")}</p>
                      </div>{" "}
                    </div>{" "}
                  </div>

                  <div className="col-lg-3 col-md-4 col-sm-6">
                    <div className="square service">
                      <div>
                        <p
                          id="squareTitle"
                          className={`${
                            currentLanguage === "ar"
                              ? "arabicStyleSquareTitle"
                              : ""
                          }`}
                        >
                          {t("services.squareTitle2")}
                        </p>
                        <div className="dark-img">
                          <img src={uiuxD} className="img-dark" />

                          <img src={uiux} className="img-light" />
                        </div>{" "}
                        <p id="squareData">{t("services.squareData2")}</p>
                      </div>{" "}
                    </div>{" "}
                  </div>

                  <div className="col-lg-3 col-md-4 col-sm-6">
                    <div className="square service">
                      <div>
                        <p
                          id="squareTitle"
                          className={`${
                            currentLanguage === "ar"
                              ? "arabicStyleSquareTitle"
                              : ""
                          }`}
                        >
                          {t("services.squareTitle3")}
                        </p>
                        <div className="dark-img">
                          <img src={graphicD} className="img-dark" />

                          <img src={graphic} className="img-light" />
                        </div>{" "}
                        <p id="squareData">{t("services.squareData3")}</p>
                      </div>{" "}
                    </div>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* end services */}


          {/* start process */}
          {/* <div className="fifthDiv container-fluid">
            <div id="fifthTitle">
              <p id="process">{t("process.process")}</p>
              <p id="helpBusiness">{t("process.helpBusiness")}</p>
            </div>
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-5 col-0 firstStepDiv">
                  <div className="stepText">
                    <p id="stepText1">{t("process.title1")}</p>
                    <p id="stepText2">{t("process.text1")}</p>
                  </div>
                  <div style={{ position: "relative" }}>
                    <div
                      className="shadow1"
                      style={{ backgroundImage: `url(${shadow1})` }}
                    ></div>
                    <div className="stepImg ">
                      <div style={{ backgroundImage: `url(${step1})` }}></div>
                    </div>
                  </div>
                  <div className="stepText">
                    <p id="stepText1">{t("process.title2")}</p>
                    <p id="stepText2">{t("process.text2")}</p>
                  </div>

                  <div
                    style={{ background: "#E1E9FC", marginBottom: 0 }}
                    className="stepImg "
                  >
                    <div style={{ backgroundImage: `url(${step2})` }}></div>
                  </div>
                </div>
                <div className="col-sm-2 col-2 stepsDiv">
                  <div id="step1">
                    <div>1</div>
                  </div>
                  <div id="line"></div>
                  <div id="line" style={{ background: "#F2F4F7" }}></div>

                  <div id="step1">
                    <div>2</div>
                  </div>
                  <div id="line" style={{ background: "#B7CAF7" }}></div>
                  <div id="line" style={{ background: "#F2F4F7" }}></div>

                  <div id="step1">
                    <div>3</div>
                  </div>
                  <div id="line" style={{ background: "#C5D4F9" }}></div>
                  <div id="line" style={{ background: "#F2F4F7" }}></div>

                  <div id="step1">
                    <div>4</div>
                  </div>
                  <div
                    id="line"
                    style={{ background: "#E1E9FC", height: "12.5vw" }}
                  ></div>
                  <div id="line" style={{ background: "#F2F4F7" }}></div>
                </div>

                <div className="col-sm-5 col-10">
                  <div style={{ background: " #8EABF3" }} className="stepImg ">
                    <div style={{ backgroundImage: `url(${step3})` }}></div>
                  </div>
                  <div className="stepText">
                    <p id="stepText1">{t("process.title3")}</p>
                    <p id="stepText2">{t("process.text3")}</p>
                  </div>

                  <div className="stepText  mobileStep">
                    <p id="stepText1">{t("process.title1")}</p>
                    <p id="stepText2">{t("process.text1")}</p>
                  </div>

                  <div className="stepImg mobileStep ">
                    <div style={{ backgroundImage: `url(${step1})` }}></div>
                  </div>
                  <div className="stepText  mobileStep">
                    <p id="stepText1">{t("process.title3")}</p>
                    <p id="stepText2">{t("process.text3")}</p>
                  </div>

                  <div style={{ position: "relative" }}>
                    <div
                      className="shadow2"
                      style={{ backgroundImage: `url(${shadow2})` }}
                    ></div>

                    <div style={{ background: "#C5D4F9" }} className="stepImg ">
                      <div style={{ backgroundImage: `url(${step4})` }}></div>
                    </div>
                  </div>

                  <div className="stepText stepText-last">
                    <p id="stepText1">{t("process.title4")}</p>
                    <p id="stepText2">{t("process.text4")}</p>
                  </div>
                  <div className="stepText mobileStep">
                    <p id="stepText1">{t("process.title2")}</p>
                    <p id="stepText2">{t("process.text2")}</p>
                  </div>

                  <div
                    style={{ background: " #E1E9FC", margin: "3.5em 0" }}
                    className="stepImg mobileStep "
                  >
                    <div style={{ backgroundImage: `url(${step2})` }}></div>
                  </div>
                  <div className="stepText mobileStep">
                    <p id="stepText1">{t("process.title4")}</p>
                    <p id="stepText2">{t("process.text4")}</p>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          {/* end process */}

          {/* build your team  */}
          <div className="thirdDiv container-fluid">
            <div className="buildDiv ">
              <div className="buildMain">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12 buildImg">
                      <div
                        className={`buildImgOne ${
                          currentLanguage === "ar"
                            ? "arabicStyleBuildImgsOne"
                            : ""
                        }`}
                      ></div>
                      <div
                        className={`buildImgTwo ${
                          currentLanguage === "ar"
                            ? "arabicStyleBuildImgsTwo"
                            : ""
                        }`}
                      ></div>
                    </div>
                    <div
                      className="col-lg-7 col-md-7 col-sm-12 col-xs-12 buildCol2"
                      style={{
                        padding: "3vw 5vw 3vw 5vw",
                        position: "relative",
                      }}
                    >
                      <p id="buildTitle">
                        <span>{t("team.build")}</span> {t("team.build2")}
                        <span className="span2">{t("team.build3")}</span>
                      </p>
                      <p id="buildTxt">{t("team.buildTxt")}</p>
                      {/* <Link to="/quotation" style={{ textDecoration: "none" }}>
                        <button
                          className={`talk ${
                            currentLanguage === "ar" ? "arabicStyleTalk" : ""
                          }`}
                          style={{ display: "inline-block" }}
                        >
                          {t("processPage.talkBtn")}{" "}
                          <img className="btnlogo" src={bdaiatWhiteLogo} />
                        </button>{" "}
                      </Link>{" "} */}
                      <button
                        onClick={() => handleGoToQuotationByBuildYourTeam()}
                        className={`talk ${
                          currentLanguage === "ar" ? "arabicStyleTalk" : ""
                        }`}
                        style={{ display: "inline-block" }}
                      >
                        {t("processPage.talkBtn")}{" "}
                        <img className="btnlogo" src={bdaiatWhiteLogo} />
                      </button>{" "}
                      <p
                        style={{
                          display: "inline-block",
                          marginLeft: currentLanguage === "ar" ? "0px" : "1em",
                          marginRight: currentLanguage === "ar" ? "1em" : "0em",
                        }}
                        id="learn"
                      >
                        {t("team.learn")}
                      </p>
                      {/* <button id="hire">{t("team.hire")}</button> */}
                      {/* <button  id="apply" onClick={()=>setModal(true)}>{t("team.freeLance")}</button> */}
                      {/* <div
                        className=" buildTri"
                        style={{ backgroundImage: `url(${buildTriangle})` }}
                      ></div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


          {/* Technologies*/}
          <div className="thirdDiv container-fluid TechDiv">
            <div className="row">
              <div
                className={`col-lg-12 col-md-12 col-sm-12 col-xs-12 techHeader ${
                  currentLanguage === "ar" ? "arabicStyleTechHeader" : ""
                }`}
              >
                <h1>{t("techs.title")}</h1>
                <p>{t("techs.text")}</p>
              </div>
            </div>

            <div className="row TechRow">
              <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6 techCol1">
                <div className="techOne">
                  <div
                    className="techOneImg"
                    style={{
                      backgroundPosition:
                        currentLanguage === "ar" ? "right" : "left",
                    }}
                  ></div>
                  <div className="techOnetext">
                    <p>
                      <span>{t("techs.techTitle1")}&nbsp;</span>
                      {t("techs.techText1")}
                    </p>
                    {/* <h4>{t("techs.techTitle1")}</h4>
                      <p>{t("techs.techText1")}</p> */}
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6 techCol1">
                <div className="techOne">
                  <div
                    className="techTwoImg"
                    style={{
                      backgroundPosition:
                        currentLanguage === "ar" ? "right" : "left",
                    }}
                  ></div>
                  <div className="techOnetext">
                    <p>
                      <span>{t("techs.techTitle2")}&nbsp;</span>
                      {t("techs.techText2")}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6 techCol1">
                <div className="techOne">
                  <div
                    className="techThreeImg"
                    style={{
                      backgroundPosition:
                        currentLanguage === "ar" ? "right" : "left",
                    }}
                  ></div>
                  <div className="techOnetext">
                    <p>
                      <span>{t("techs.techTitle3")}&nbsp;</span>
                      {t("techs.techText3")}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6 techCol1">
                <div className="techOne">
                  <div
                    className="techFourImg"
                    style={{
                      backgroundPosition:
                        currentLanguage === "ar" ? "right" : "left",
                    }}
                  ></div>
                  <div className="techOnetext">
                    <p>
                      <span>{t("techs.techTitle4")}&nbsp;</span>
                      {t("techs.techText4")}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row TechRow">
              <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6 techCol1">
                <div className="techOne">
                  <div
                    className="techFiveImg"
                    style={{
                      backgroundPosition:
                        currentLanguage === "ar" ? "right" : "left",
                    }}
                  ></div>
                  <div className="techOnetext">
                    <p>
                      <span>{t("techs.techTitle5")}&nbsp;</span>
                      {t("techs.techText5")}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6 techCol1">
                <div className="techOne">
                  <div
                    className="techSixImg"
                    style={{
                      backgroundPosition:
                        currentLanguage === "ar" ? "right" : "left",
                    }}
                  ></div>
                  <div className="techOnetext">
                    <p>
                      <span>{t("techs.techTitle6")}&nbsp;</span>
                      {t("techs.techText6")}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6 techCol1">
                <div className="techOne">
                  <div
                    className="techSevenImg"
                    style={{
                      backgroundPosition:
                        currentLanguage === "ar" ? "right" : "left",
                    }}
                  ></div>
                  <div className="techOnetext">
                    <p>
                      <span>{t("techs.techTitle7")}&nbsp;</span>
                      {t("techs.techText7")}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6 techCol1">
                <div className="techOne">
                  <div
                    className="techEightImg"
                    style={{
                      backgroundPosition:
                        currentLanguage === "ar" ? "right" : "left",
                    }}
                  ></div>
                  <div className="techOnetext">
                    <p>
                      <span>{t("techs.techTitle8")}&nbsp;</span>
                      {t("techs.techText8")}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row TechRow">
              <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6 techCol1">
                <div className="techOne">
                  <div
                    className="techNineImg"
                    style={{
                      backgroundPosition:
                        currentLanguage === "ar" ? "right" : "left",
                    }}
                  ></div>
                  <div className="techOnetext">
                    <p>
                      <span>{t("techs.techTitle9")}&nbsp;</span>
                      {t("techs.techText9")}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6 techCol1">
                <div className="techOne">
                  <div
                    className="techTenImg"
                    style={{
                      backgroundPosition:
                        currentLanguage === "ar" ? "right" : "left",
                    }}
                  ></div>
                  <div className="techOnetext">
                    <p>
                      <span>{t("techs.techTitle10")}&nbsp;</span>
                      {t("techs.techText10")}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6 techCol1">
                <div className="techOne">
                  <div
                    className="techElevenImg"
                    style={{
                      backgroundPosition:
                        currentLanguage === "ar" ? "right" : "left",
                    }}
                  ></div>
                  <div className="techOnetext">
                    <p>
                      <span>{t("techs.techTitle11")}&nbsp;</span>
                      {t("techs.techText11")}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6 techCol1">
                <div className="techOne">
                  <div
                    className="techTwelveImg"
                    style={{
                      backgroundPosition:
                        currentLanguage === "ar" ? "right" : "left",
                    }}
                  ></div>
                  <div className="techOnetext">
                    <p>
                      <span>{t("techs.techTitle12")}&nbsp;</span>
                      {t("techs.techText12")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div>
            <button onClick={handleClick} className="scroll-button">
              Scroll to Section
            </button>
          </div> */}


          {/* besalla app banner */}
          <div className="thirdDiv container-fluid">
            <div className={`container-fluid besallaWhiteDiv ${currentLanguage === "ar" ? "arabicStyleBesallaWhiteDiv" : "" }`}>
              <div
                  className={`besallaImgLogo ${
                    currentLanguage === "ar"
                      ? "arabicStyleBesallaImgLogo"
                      : ""
                  }`}
                ></div>
            </div>
            <div className="besallaDiv ">
              <div className="besallaMain">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12 besallaImg">
                      <div
                        className={`besallaImgOne ${
                          currentLanguage === "ar"
                            ? "arabicStyleBesallaImgsOne"
                            : ""
                        }`}
                      ></div>
                      <div
                        className={`besallaImgTwo ${
                          currentLanguage === "ar"
                            ? "arabicStyleBesallaImgsTwo"
                            : ""
                        }`}
                      ></div>
                    </div>
                    <div
                      className="col-lg-7 col-md-7 col-sm-12 col-xs-12 besallaCol2"
                      style={{
                        padding: "3vw 5vw 3vw 5vw",
                        position: "relative",
                      }}
                    >
                      <p id="besallaTitle">
                        <span>{t("besalla.build")}</span> {t("besalla.build2")}
                        <span className="span2">{t("besalla.build3")}</span>
                      </p>
                      <p id="besallaTxt">{t("besalla.buildTxt")}</p>

                      <Link to="/besalla" className={`talk ${currentLanguage === "ar" ? "arabicStyleTalk" : ""}`}>
                        {t("besalla.besallaBtn")}
                        <img className="btnlogo" src={bdaiatWhiteLogo} alt="logo" />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


          {/* Our Team */}
          <div className="thirdDiv container-fluid TeamDiv">
            <div className="row titleRow">
              <p id="ourTeam" ref={teamRef}>
                {t("team.title1")}{" "}
                <span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </span>
              </p>
            </div>
            <div
              className={`row gy-5 teamRow ${
                currentLanguage === "ar" ? "arabicStyle" : ""
              }`}
            >
              <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 cardCol">
                <div id="card">
                  <div
                    className="cardImg"
                    style={{ backgroundImage: `url(${cardOneImage})` }}
                  ></div>
                  <div
                    className={`cardLogoMob ${
                      currentLanguage === "ar" ? "arabicStyleCardLogoMob" : ""
                    }`}
                    style={{ backgroundImage: `url(${cardOneLogo})` }}
                  ></div>
                  <div className="cardInfo">
                    <p
                      className={`cardInfoParaAr ${
                        currentLanguage === "ar" ? "arabicStyle" : ""
                      }`}
                    >
                      <span>{t("team.name1")}&nbsp;</span>
                      {t("team.cardInfo1")}
                    </p>
                  </div>
                  <div
                    className="cardLogo"
                    style={{ backgroundImage: `url(${cardOneLogo})` }}
                  ></div>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 cardCol">
                <div id="card">
                  <div
                    className="cardImg"
                    style={{ backgroundImage: `url(${cardTwoImage})` }}
                  ></div>
                  <div
                    className={`cardLogoMob ${
                      currentLanguage === "ar" ? "arabicStyleCardLogoMob" : ""
                    }`}
                    style={{ backgroundImage: `url(${cardTwoLogo})` }}
                  ></div>
                  <div className="cardInfo">
                    <p
                      className={`cardInfoParaAr ${
                        currentLanguage === "ar" ? "arabicStyle" : ""
                      }`}
                    >
                      <span>{t("team.name2")}&nbsp;</span>
                      {t("team.cardInfo2")}
                    </p>
                  </div>
                  <div
                    className="cardLogo"
                    style={{ backgroundImage: `url(${cardTwoLogo})` }}
                  ></div>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 cardCol">
                <div id="card">
                  <div
                    className="cardImg"
                    style={{ backgroundImage: `url(${cardThreeImage})` }}
                  ></div>
                  <div
                    className={`cardLogoMob ${
                      currentLanguage === "ar" ? "arabicStyleCardLogoMob" : ""
                    }`}
                    style={{ backgroundImage: `url(${cardThreeLogo})` }}
                  ></div>
                  <div className="cardInfo">
                    <p
                      className={`cardInfoParaAr ${
                        currentLanguage === "ar" ? "arabicStyle" : ""
                      }`}
                    >
                      <span>{t("team.name3")}&nbsp;</span>
                      {t("team.cardInfo3")}
                    </p>
                  </div>
                  <div
                    className="cardLogo"
                    style={{ backgroundImage: `url(${cardThreeLogo})` }}
                  ></div>
                </div>
              </div>
              {/* <div className="col-lg-2 col-md-2 col-sm-6 col-xs-12 cardCol">
                <div id="card">
                  <div
                    className="cardImg"
                    style={{ backgroundImage: `url(${cardFourImage})` }}
                  ></div>
                  <div
                    className={`cardLogoMob ${
                      currentLanguage === "ar" ? "arabicStyleCardLogoMob" : ""
                    }`}
                    style={{ backgroundImage: `url(${cardFourLogo})` }}
                  ></div>
                  <div className="cardInfo">
                    <p
                      className={`cardInfoParaAr ${
                        currentLanguage === "ar" ? "arabicStyle" : ""
                      }`}
                    >
                      <span>{t("team.name4")}&nbsp;</span>
                      {t("team.cardInfo4")}
                    </p>
                  </div>
                  <div
                    className="cardLogo"
                    style={{ backgroundImage: `url(${cardFourLogo})` }}
                  ></div>
                </div>
              </div> */}
              <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 specialCardCol">
                <div id="card">
                  <div
                    className={`specialCardTitle ${
                      currentLanguage === "ar"
                        ? "arabicStyleSpecialCardTitle"
                        : ""
                    }`}
                  >
                    <p id="specialTitleOne">21</p>
                    <p id="specialTitleTwo">Engineers</p>
                  </div>
                  <div
                    className={`cardLogoMob ${
                      currentLanguage === "ar" ? "arabicStyleCardLogoMob" : ""
                    }`}
                    style={{ backgroundImage: `url(${cardFiveLogo})` }}
                  ></div>
                  <div className="cardInfo">
                    <p
                      className={`cardInfoParaArSp ${
                        currentLanguage === "ar" ? "arabicStyle" : ""
                      }`}
                    >
                      {t("team.special5")}&nbsp;
                      <span>{t("team.name5")}&nbsp;</span>
                      {t("team.cardInfo5")}
                    </p>
                  </div>
                  <div
                    className="cardLogo"
                    style={{ backgroundImage: `url(${cardFiveLogo})` }}
                  ></div>
                </div>
              </div>
            </div>
            {/* end Team */}

            {/* Technologies*/}
            {/* <div className="container-fluid TechDiv">
              <div className="row">
                <div className={`col-lg-12 col-md-12 col-sm-12 col-xs-12 techHeader ${currentLanguage === 'ar' ? 'arabicStyleTechHeader' : ''}`}>
                  <h1>{t("techs.title")}</h1>
                  <p>{t("techs.text")}</p>
                </div>
              </div>

              <div className="row TechRow">
                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6 techCol1">
                  <div className="techOne">
                    <div className="techOneImg" style={{backgroundPosition : currentLanguage === 'ar' ? 'right': 'left'}}></div>
                    <div className="techOnetext">
                      <p><span>{t("techs.techTitle1")}&nbsp;</span>{t("techs.techText1")}</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6 techCol1">
                  <div className="techOne">
                    <div className="techTwoImg" style={{backgroundPosition : currentLanguage === 'ar' ? 'right': 'left'}}></div>
                    <div className="techOnetext">
                      <p><span>{t("techs.techTitle2")}&nbsp;</span>{t("techs.techText2")}</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6 techCol1">
                  <div className="techOne">
                    <div className="techThreeImg" style={{backgroundPosition : currentLanguage === 'ar' ? 'right': 'left'}}></div>
                    <div className="techOnetext">
                      <p><span>{t("techs.techTitle3")}&nbsp;</span>{t("techs.techText3")}</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6 techCol1">
                  <div className="techOne">
                    <div className="techFourImg" style={{backgroundPosition : currentLanguage === 'ar' ? 'right': 'left'}}></div>
                    <div className="techOnetext">
                      <p><span>{t("techs.techTitle4")}&nbsp;</span>{t("techs.techText4")}</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row TechRow">
                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6 techCol1">
                  <div className="techOne">
                    <div className="techFiveImg" style={{backgroundPosition : currentLanguage === 'ar' ? 'right': 'left'}}></div>
                    <div className="techOnetext">
                      <p><span>{t("techs.techTitle5")}&nbsp;</span>{t("techs.techText5")}</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6 techCol1">
                  <div className="techOne">
                    <div className="techSixImg" style={{backgroundPosition : currentLanguage === 'ar' ? 'right': 'left'}}></div>
                    <div className="techOnetext">
                      <p><span>{t("techs.techTitle6")}&nbsp;</span>{t("techs.techText6")}</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6 techCol1">
                  <div className="techOne">
                    <div className="techSevenImg" style={{backgroundPosition : currentLanguage === 'ar' ? 'right': 'left'}}></div>
                    <div className="techOnetext">
                      <p><span>{t("techs.techTitle7")}&nbsp;</span>{t("techs.techText7")}</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6 techCol1">
                  <div className="techOne">
                    <div className="techEightImg" style={{backgroundPosition : currentLanguage === 'ar' ? 'right': 'left'}}></div>
                    <div className="techOnetext">
                      <p><span>{t("techs.techTitle8")}&nbsp;</span>{t("techs.techText8")}</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row TechRow">
                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6 techCol1">
                  <div className="techOne">
                    <div className="techNineImg" style={{backgroundPosition : currentLanguage === 'ar' ? 'right': 'left'}}></div>
                    <div className="techOnetext">
                      <p><span>{t("techs.techTitle9")}&nbsp;</span>{t("techs.techText9")}</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6 techCol1">
                  <div className="techOne">
                    <div className="techTenImg" style={{backgroundPosition : currentLanguage === 'ar' ? 'right': 'left'}}></div>
                    <div className="techOnetext">
                      <p><span>{t("techs.techTitle10")}&nbsp;</span>{t("techs.techText10")}</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6 techCol1">
                  <div className="techOne">
                    <div className="techElevenImg" style={{backgroundPosition : currentLanguage === 'ar' ? 'right': 'left'}}></div>
                      <div className="techOnetext">
                      <p><span>{t("techs.techTitle11")}&nbsp;</span>{t("techs.techText11")}</p>
                      </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6 techCol1">
                  <div className="techOne">
                    <div className="techTwelveImg" style={{backgroundPosition : currentLanguage === 'ar' ? 'right': 'left'}}></div>
                    <div className="techOnetext">
                      <p><span>{t("techs.techTitle12")}&nbsp;</span>{t("techs.techText12")}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            {/* end Technologies */}
          </div>


          {/* old build  */}
          {/* <div className="thirdDiv container-fluid">
            <div className="buildDiv ">
              <div className="buildMain">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-7"style={{ padding: "5vw", position: "relative" }}>
                      <p id="buildTitle">
                        <span>{t("team.build")}</span> {t("team.build2")}
                      </p>
                      <p id="buildTxt">{t("team.buildTxt")}</p>
                      <p id="learn">{t("team.learn")}</p>
                      <button id="hire">{t("team.hire")}</button>
                      <div
                        className=" buildTri"
                        style={{ backgroundImage: `url(${buildTriangle})` }}
                      ></div>
                    </div>
                    <div className="col-md-5 buildImg" style={{ backgroundImage: `url(${Content})` }}></div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          {/* dark here */}

          <div
            id="targetDiv"
            className={`container-fluid process4 ${
              currentLanguage === "ar" ? "processFlib-background2" : ""
            }`}
          >
            <div className="row ">
              <div
                className={`col-lg-8 col-md-8 col-sm-12 col-xs-12 alignVert2 ${
                  currentLanguage === "ar" ? "alignVertArabicStyle" : ""
                }`}
              >
                <div>
                  <div style={{ zIndex: "1", position: "relative" }}>
                    <p id="turnIdeas">{t("processPage.ready")}</p>
                    <p id="powerful2">{t("processPage.ready2")} </p>
                  </div>
                  <div className="btnsDiv">
                    {/* <Link to="/quotation?source=homekick">
                      <button
                        className={`talk ${
                          currentLanguage === "ar" ? "arabicStyleTalk" : ""
                        }`}
                      >
                        {t("processPage.talkBtn")}{" "}
                        <img className="btnlogo" src={bdaiatWhiteLogo} />
                      </button>{" "}
                    </Link> */}
                    <button
                      onClick={() => handleGoToQuotationByHomeKickOff()}
                      className={`talk ${
                        currentLanguage === "ar" ? "arabicStyleTalk" : ""
                      }`}
                    >
                      {t("processPage.talkBtn")}{" "}
                      <img className="btnlogo" src={bdaiatWhiteLogo} />
                    </button>{" "}
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 col5Responsive">
                <div className="processImgLogo">
                  <div
                    className={`btnlogo ${
                      currentLanguage === "ar" ? "arabicStyleBdaiatLogoWH" : ""
                    }`}
                  ></div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="ninethDiv">
        <div id="reqQuestion">
          <div className="trianglePlacement">
            <div
              id="triangles"
              style={{ backgroundImage: `url(${triangles})` }}
            ></div>

            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-6">
                  <p id="question">{t("footer.quotation")}!</p>
                  <p id="begin">{t("footer.txt")}</p>
                </div>

                <div
                  className="col-sm-6"
                  style={
                    i18n.language == "ar"
                      ? { textAlign: "left" }
                      : { textAlign: "right" }
                  }
                >
                  <Link to="/quotation">
                    <button id="questionBtn">{t("navbar.quotation")}</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
          </div> */}


      {/* blogs section */}
      {blogs && blogs.length > 0 && (
        <div className="eightthDiv">
          <div
            className="shadow2"
            style={{ backgroundImage: `url(${shadow2})` }}
          ></div>
          <div className="spaceBtn">
            <p id="ourTeam">
              {t("blog.our")}{" "}
              <span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </span>
            </p>
            <Link to="/blogs">
              {" "}
              <button className="request viewAll">
                {t("blog.view")}
              </button>
            </Link>
          </div>
          <p id="doesMore">{t("blog.proud")}</p>
          <div className="container-fluid" style={{ marginTop: "64px" }}>
            <div className="row gy-4 strech">
              {blogs &&
                blogs.slice(0, 3).map((data, index) => (
                  <div className="col-lg-4 col-sm-6" key={index}>
                    <div className="projectDiv">
                      <div className="projImg">
                        <div
                          style={{ backgroundImage: `url(${data.image})` }}
                        ></div>
                      </div>
                      <div className="projData">
                        <div className="projType" id="type1">
                          <BsCircleFill size={7} color=" #7A5AF8" />
                          <p> {t("blog.design")}</p>
                        </div>
                        <p id="projName">{data.title}</p>
                        <p id="projDetail">{data.description}</p>
                      </div>
                      <Link to={`/blogs/${data.id}`}>
                        <button id="viewProj">
                          {t("blog.details")}{" "}
                          <BsArrowRightShort size={27} />
                        </button>
                      </Link>
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <Link to="/blogs" style={{ textDecoration: 'none'}}>
              {" "}
              <button className="request viewAllMobile">
                {t("blog.view")}
              </button>
            </Link>
          {/* <button className="request viewAllMobile">
            {t("blog.view")}
          </button> */}
        </div>
      )}

        </>
      )}
      <Footer />
    </div>
  );
}

export default Home;
